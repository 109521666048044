import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, Typography } from '@material-ui/core'
import { CardBase } from 'components/display'

const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: '25px 0'
  },
  filterRow: {
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      paddingRight: '15px'
    }
  },
  label: {
    color: theme.palette.grey[600],
    fontWeight: 300
  }
}))

const Teachers = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <CardBase noPadding={false} bg="white" align="left">
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.filterRow}>
              <Typography className={classes.label} variant="body2">
                Type
              </Typography>
              <Button variant="text" color="default" size="small">
                All
              </Button>
              <Button variant="text" color="default" size="small">
                Term Class
              </Button>
              <Button variant="text" color="default" size="small">
                Summer Class
              </Button>
              <Button variant="text" color="default" size="small">
                Master Class
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.filterRow}>
              <Typography className={classes.label} variant="body2">
                Grade
              </Typography>
              <Button variant="text" color="default" size="small">
                All
              </Button>
              <Button variant="text" color="default" size="small">
                K
              </Button>
              <Button variant="text" color="default" size="small">
                G1
              </Button>
              <Button variant="text" color="default" size="small">
                G2
              </Button>
              <Button variant="text" color="default" size="small">
                G3
              </Button>
              <Button variant="text" color="default" size="small">
                G4
              </Button>
              <Button variant="text" color="default" size="small">
                G5
              </Button>
              <Button variant="text" color="default" size="small">
                G6
              </Button>
              <Button variant="text" color="default" size="small">
                G7
              </Button>
              <Button variant="text" color="default" size="small">
                G8
              </Button>
              <Button variant="text" color="default" size="small">
                G9
              </Button>
              <Button variant="text" color="default" size="small">
                G10
              </Button>
              <Button variant="text" color="default" size="small">
                G11
              </Button>
              <Button variant="text" color="default" size="small">
                G12
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.filterRow}>
            <Typography className={classes.label} variant="body2">
              Subject
            </Typography>
            <Button variant="text" color="default" size="small">
              All
            </Button>
            <Button variant="text" color="default" size="small">
              BHG
            </Button>
            <Button variant="text" color="default" size="small">
              CCA
            </Button>
          </div>
        </Grid>
      </CardBase>
    </div>
  )
}

export default Teachers
