import { Router, Route, Redirect, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Provider } from 'mobx-react'
import { AppStore } from 'stores'
import WithLayout from 'with-layout'
import { Main as MainLayout, Minimal as MinimalLayout } from 'layouts'

import HomePage from './home'
import AboutPage from './about'
import CoursePage from './course'
import LoginPage from './login'
import NotFoundPage from './not-found'

import 'styles/index.css'

const browserHistory = createBrowserHistory()
const defaultStore = new AppStore()

const App = () => {
  return (
    <Provider store={defaultStore}>
      <Router history={browserHistory}>
        <Switch>
          <Route
            exact
            path="/"
            render={matchProps => <WithLayout {...matchProps} component={HomePage} layout={MainLayout} />}
          />
          <Route
            exact
            path="/about"
            render={matchProps => <WithLayout {...matchProps} component={AboutPage} layout={MainLayout} />}
          />
          <Route
            exact
            path="/course"
            render={matchProps => <WithLayout {...matchProps} component={CoursePage} layout={MainLayout} />}
          />
          <Route
            exact
            path="/login"
            render={matchProps => <WithLayout {...matchProps} component={LoginPage} layout={MainLayout} />}
          />
          <Route
            exact
            path="/not-found"
            render={matchProps => <WithLayout {...matchProps} component={NotFoundPage} layout={MinimalLayout} />}
          />
          <Redirect to="/not-found" />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
