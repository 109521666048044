import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Toolbar, Hidden, List, ListItem, ListItemIcon, Popover, Typography, IconButton } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MenuIcon from '@material-ui/icons/Menu'
import { Image } from 'components/html'
import Logo from 'assets/logo.png'

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  toolbar: {
    zIndex: 999,
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8)
    }
  },
  navLink: {
    '&:hover': {
      color: theme.palette.primary.dark
    }
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.primary.dark
    },
    '&.menu-item--no-dropdown': {
      paddingRight: 0
    }
  },
  listItemActive: {
    '&> .menu-item': {
      color: theme.palette.primary.dark
    }
  },
  listItemText: {
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
    fontFamily: 'Anton',
    padding: '0 12px'
  },
  listItemButton: {
    whiteSpace: 'nowrap'
  },
  listItemIcon: {
    minWidth: 'auto'
  },
  popover: {
    padding: theme.spacing(4),
    border: theme.spacing(2),
    boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
    minWidth: 350,
    marginTop: theme.spacing(2)
  },
  iconButton: {
    marginLeft: theme.spacing(2),
    padding: 0,
    '&:hover': {
      background: 'transparent'
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.primary.dark
  },
  logoContainer: {
    width: 100,
    height: 28,
    [theme.breakpoints.up('md')]: {
      width: 120,
      height: 32
    }
  },
  logoImage: {
    width: 'auto',
    height: '100%'
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  menuItem: {
    marginRight: theme.spacing(5),
    '&:last-child': {
      marginRight: 0
    }
  },
  menuGroupItem: {
    paddingTop: 0
  },
  menuGroupTitle: {
    textTransform: 'uppercase'
  },
  fontPoppins: {
    fontFamily: 'Poppins !important'
  },
  loginContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const Topbar = ({ onSidebarOpen, pages, className, ...rest }: any) => {
  const classes = useStyles()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState(null)
  const [openedPopoverId, setOpenedPopoverId] = useState('')

  const handleClick = (event: any, popoverId: string) => {
    setAnchorEl(event.target)
    setOpenedPopoverId(popoverId)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenedPopoverId('')
  }

  const landings = pages.landings

  const MenuGroup = (props: any) => {
    const { item } = props
    return (
      <List disablePadding>
        <ListItem disableGutters>
          <Typography variant="body2" color="primary" className={classes.menuGroupTitle}>
            {item.groupTitle}
          </Typography>
        </ListItem>
        {item.pages.map((page: any, i: number) => (
          <ListItem disableGutters key={i} className={classes.menuGroupItem}>
            <Typography
              variant="body1"
              component={'a'}
              href={page.href}
              className={clsx(classes.navLink, 'submenu-item')}
              color="textSecondary"
              onClick={handleClose}>
              {page.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    )
  }

  const LandingPages = () => {
    const { web } = landings.children
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={web} />
        </div>
      </div>
    )
  }

  const renderPages = (id: string) => {
    if (id === 'landing-pages') {
      return <LandingPages />
    }
  }

  return (
    <Toolbar disableGutters className={classes.toolbar} {...rest}>
      <div className={classes.logoContainer}>
        <a href="/" title="acalyt">
          <Image className={classes.logoImage} src={Logo} alt="acalyt" lazy={false} />
        </a>
      </div>
      <div className={classes.flexGrow} />
      <Hidden smDown>
        <List disablePadding className={classes.navigationContainer}>
          <ListItem className={clsx(classes.listItem)}>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.listItemText}
              onClick={() => {
                history.push('/')
              }}>
              Home
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.listItemText}
              onClick={() => {
                history.push('/about')
              }}>
              About
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.listItemText}
              onClick={() => {
                history.push('/course')
              }}>
              Course
            </Typography>
            <div className={classes.loginContainer}>
              <AccountCircleIcon color="primary" />
              <Typography
                variant="body1"
                color="textPrimary"
                className={clsx(classes.listItemText, classes.fontPoppins)}
                onClick={() => {
                  history.push('/login')
                }}>
                Login
              </Typography>
            </div>
          </ListItem>
          {[landings].map(page => (
            <div key={page.id} style={{ display: 'none' }}>
              <ListItem
                aria-describedby={page.id}
                onClick={e => handleClick(e, page.id)}
                className={clsx(classes.listItem, openedPopoverId === page.id ? classes.listItemActive : '')}>
                <Typography variant="body1" color="textPrimary" className={clsx(classes.listItemText, 'menu-item')}>
                  {page.title}
                </Typography>
                <ListItemIcon className={classes.listItemIcon}>
                  <ExpandMoreIcon className={openedPopoverId === page.id ? classes.expandOpen : ''} fontSize="small" />
                </ListItemIcon>
              </ListItem>
              <Popover
                elevation={1}
                id={page.id}
                open={openedPopoverId === page.id}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                classes={{ paper: classes.popover }}>
                <div>{renderPages(page.id)}</div>
              </Popover>
            </div>
          ))}
        </List>
      </Hidden>
      <Hidden mdUp>
        <IconButton className={classes.iconButton} onClick={onSidebarOpen} aria-label="Menu">
          <MenuIcon />
        </IconButton>
      </Hidden>
    </Toolbar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object.isRequired
}

export default Topbar
