import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

const getTheme = () =>
  responsiveFontSizes(
    createMuiTheme({
      palette: {
        alternate: {
          main: 'rgb(247, 249, 250)',
          dark: '#e8eaf6'
        },
        cardShadow: 'rgba(23, 70, 161, .11)',
        type: 'light',
        primary: {
          main: '#20ce88',
          light: 'rgb(35, 235, 154)',
          dark: 'rgb(27, 179, 117)',
          contrastText: '#fff'
          //main: '#3f51b5',
          //light: 'rgb(71, 145, 219)',
          //dark: 'rgb(17, 82, 147)',
          //contrastText: '#fff'
        },
        secondary: {
          light: '#ffb74d',
          main: '#f9b934',
          dark: '#f57c00',
          contrastText: 'rgba(0, 0, 0, 0.87)'
        },
        text: {
          primary: '#2d3748',
          secondary: '#718096'
        },
        divider: 'rgba(0, 0, 0, 0.12)',
        background: {
          paper: '#fff',
          default: '#fff',
          level2: '#f5f5f5',
          level1: '#fff',
          footer: '#1b1642',
          primary: '#20ce88'
        }
      },
      layout: {
        contentWidth: 1236
      },
      typography: {
        fontFamily: ["'Poppins', sans-serif", 'Anton', 'Chelsea Market'].join(','),
        fontSize: 18,
        fontWeightRegular: 200,
        button: {
          textTransform: 'capitalize'
        }
      },
      zIndex: {
        appBar: 1200,
        drawer: 1100
      }
    })
  )

export default getTheme
