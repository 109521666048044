import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { CustomLink } from 'components/html'
import { Section, SectionHeader } from 'components/display'

const useStyles = makeStyles(theme => ({
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - 64px)`,
    maxWidth: 500,
    margin: `0 auto`
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase'
  }
}))

const NotFound = () => {
  const classes = useStyles()

  const handleClick = () => {
    window.history.back()
  }

  return (
    <div>
      <Section className={classes.section}>
        <div className={classes.formContainer}>
          <SectionHeader
            label="404"
            title="Uh oh."
            subtitle={
              <span>
                There’s nothing here, but if you feel this is an error please&nbsp;
                <CustomLink title="let us know" href="#" typographyProps={{ variant: 'h6' }} />
              </span>
            }
            titleProps={{
              variant: 'h3'
            }}
            labelProps={{
              color: 'secondary',
              className: classes.label,
              variant: 'h5'
            }}
            ctaGroup={[
              <Button size="large" variant="contained" color="primary" onClick={handleClick}>
                Go Back
              </Button>
            ]}
            disableGutter
          />
        </div>
      </Section>
    </div>
  )
}

export default NotFound
