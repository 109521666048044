import { Section } from 'components/display'
import { Filter } from './components'

const Course = () => {
  return (
    <div>
      <Section disablePadding>
        <Filter />
      </Section>
    </div>
  )
}

export default Course
