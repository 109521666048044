import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%'
  },
  dBlock: {
    display: 'block'
  }
}))

const Image = (props: any) => {
  const { src, srcSet, alt, lazy, lazyProps, className, ...rest } = props

  const classes = useStyles()
  if (lazy) {
    return (
      <LazyLoadImage
        className={clsx('image', classes.root, classes.dBlock, className)}
        alt={alt}
        src={src}
        srcSet={srcSet}
        effect="opacity"
        {...lazyProps}
        {...rest}
      />
    )
  }

  return <img className={clsx('image', classes.root, className)} alt={alt} src={src} srcSet={srcSet} {...rest} />
}

Image.defaultProps = {
  alt: '...',
  lazy: true,
  lazyProps: {
    width: 'auto',
    height: 'auto'
  }
}

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  alt: PropTypes.string,
  lazyProps: PropTypes.object,
  lazy: PropTypes.bool
}

export default Image
