import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, List, ListItem, Typography } from '@material-ui/core'
import { Image } from 'components/html'
import Logo from 'assets/logo.png'
import FooterQR from 'assets/footer-qr.jpg'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 0)
    },
    background: theme.palette.background.footer
  },
  footerContainer: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8)
    }
  },
  logoContainerItem: {
    paddingTop: 0
  },
  logoContainer: {
    width: 120,
    height: 80
  },
  logoImage: {
    width: 'auto',
    height: '100%'
  },
  qrContainer: {
    height: 150
  },
  qrImage: {
    width: 'auto',
    height: '100%'
  },
  groupTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(1)
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: 'rgba(255,255,255,.6)',
    '&:hover': {
      background: 'transparent'
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  icon: {
    fontSize: 24
  },
  menuListContainer: {
    padding: '0 !important'
  },
  menu: {
    display: 'flex'
  },
  menuItem: {
    margin: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0
    }
  },
  menuGroupItem: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1 / 2),
    '&:last-child': {
      paddingBottom: 0
    }
  },
  menuGroupTitle: {
    color: 'white'
  },
  divider: {
    width: '100%'
  },
  navLink: {
    color: 'rgba(255,255,255,.6)'
  },
  contactTable: {
    borderSpacing: '8px'
  }
}))

const Footer = (props: any) => {
  const { pages, className, ...rest } = props
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.footerContainer}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <List disablePadding>
              <ListItem disableGutters className={classes.logoContainerItem}>
                <div className={classes.logoContainer}>
                  <a href="/" title="Acalyt">
                    <Image className={classes.logoImage} src={Logo} alt="acalyt" lazy={false} />
                  </a>
                </div>
              </ListItem>
              <ListItem disableGutters>
                <div className={classes.qrContainer}>
                  <Image className={classes.qrImage} src={FooterQR} alt="Acalyt QR" lazy={false} />
                </div>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={4} className={classes.menuListContainer}>
            <Grid container spacing={0}>
              <div className={classes.menu}>
                <div>
                  <List disablePadding className={classes.menuItem}>
                    <ListItem disableGutters className={classes.menuGroupItem}>
                      <Typography variant="body2" className={classes.menuGroupTitle}>
                        Learning Resrouces Sharing
                      </Typography>
                    </ListItem>
                    <ListItem disableGutters className={classes.menuGroupItem}></ListItem>
                  </List>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} className={classes.menuListContainer}>
            <Grid container spacing={0}>
              <div className={classes.menu}>
                <div>
                  <List disablePadding className={classes.menuItem}>
                    <ListItem disableGutters className={classes.menuGroupItem}>
                      <Typography variant="body2" className={classes.menuGroupTitle}>
                        Contact
                      </Typography>
                    </ListItem>
                    <ListItem disableGutters className={classes.menuGroupItem}>
                      <table className={classes.contactTable}>
                        <tbody>
                          <tr>
                            <td>
                              <Typography variant="body2" className={classes.menuGroupTitle}>
                                Tel
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2" className={classes.menuGroupTitle}>
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2" className={classes.menuGroupTitle}>
                                +65 1234 5678
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                              <Typography variant="body2" className={classes.menuGroupTitle}>
                                +86 12 5678 3456
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="body2" className={classes.menuGroupTitle}>
                                Email
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2" className={classes.menuGroupTitle}>
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2" className={classes.menuGroupTitle}>
                                info@acalyt.com
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                              <Typography variant="body2" className={classes.menuGroupTitle}>
                                career@acalyt.com
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">
                              <Typography variant="body2" className={classes.menuGroupTitle}>
                                Address
                              </Typography>
                            </td>
                            <td valign="top">
                              <Typography variant="body2" className={classes.menuGroupTitle}>
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                              </Typography>
                            </td>
                            <td valign="top">
                              <Typography variant="body2" className={classes.menuGroupTitle}>
                                1234, Block xyz, 123456, Singapore
                              </Typography>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </ListItem>
                  </List>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired
}

export default Footer
