import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent } from '@material-ui/core'

interface CardBaseProps {
  className?: string
  children?: React.ReactNode
  withShadow?: boolean
  noShadow?: boolean
  noBorder?: boolean
  noPadding?: boolean
  bg?: string
  liftUp?: boolean
  align?: string
  cardContentProps?: object
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%'
  },
  withShadow: {
    boxShadow: `0 2px 10px 0 ${theme.palette.cardShadow}`
  },
  noShadow: {
    boxShadow: 'none'
  },
  noBorder: {
    border: 0
  },
  transparent: {
    background: 'transparent'
  },
  primary: {
    background: theme.palette.background.primary
  },
  noPadding: {
    padding: '0 !important'
  },
  liftUp: {
    transition: 'box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease',
    '&:hover': {
      boxShadow: '0 1.5rem 2.5rem rgba(22,28,45,.1),0 .3rem 0.5rem -.50rem rgba(22,28,45,.05) !important',
      transform: 'translate3d(0,-5px,0)'
    }
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 1),
    '&:last-child': {
      padding: theme.spacing(2, 1)
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 2),
      '&:last-child': {
        padding: theme.spacing(3, 2)
      }
    }
  },
  left: {
    alignItems: 'flex-start'
  },
  right: {
    alignItems: 'flex-end'
  },
  center: {
    alignItems: 'center'
  }
}))

const CardBase = (props: CardBaseProps) => {
  const {
    withShadow,
    noShadow,
    noBorder,
    noPadding,
    bg,
    liftUp,
    children,
    align,
    className,
    cardContentProps,
    ...rest
  } = props
  const classes = useStyles()

  return (
    <Card
      className={clsx(
        'card-base',
        classes.root,
        withShadow ? classes.withShadow : {},
        noShadow ? classes.noShadow : {},
        noBorder ? classes.noBorder : {},
        liftUp ? classes.liftUp : {},
        classes[bg as 'primary' | 'transparent'],
        className
      )}
      {...rest}>
      <CardContent
        className={clsx(
          'card-base__content',
          classes.content,
          classes[align as 'left' | 'right' | 'center'],
          noPadding ? classes.noPadding : {}
        )}
        {...cardContentProps}>
        {children}
      </CardContent>
    </Card>
  )
}

export default CardBase
