import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Toolbar } from '@material-ui/core'
import { Image } from 'components/html'
import Logo from 'assets/logo.png'

const useStyles = makeStyles(theme => ({
  toolbar: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8)
    }
  },
  logoContainer: {
    width: 100,
    height: 28,
    [theme.breakpoints.up('md')]: {
      width: 120,
      height: 32
    }
  },
  logoImage: {
    width: 'auto',
    height: '100%'
  }
}))

const Topbar = ({ className, ...rest }: any) => {
  const classes = useStyles()

  return (
    <Toolbar className={clsx(classes.toolbar, className)} {...rest}>
      <div className={classes.logoContainer}>
        <a href="/" title="Acalyt">
          <Image className={classes.logoImage} src={Logo} alt="acalyt" lazy={false} />
        </a>
      </div>
    </Toolbar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string
}

export default Topbar
