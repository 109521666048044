import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Button, TextField } from '@material-ui/core'
import validate from 'validate.js'
import { Section, SectionHeader } from 'components/display'
import { CustomLink } from 'components/html'

const useStyles = makeStyles(theme => ({
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - 64px)`,
    maxWidth: 500,
    margin: `0 auto`
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0
  },
  fullWidth: {
    width: '100%'
  }
}))

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 300
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 8
    }
  }
}

interface ILogin {
  isValid: boolean;
  values: Record<string, any>;
  touched: Record<string, any>;
  errors: Record<string, any>;
}

const Login = () => {
  const classes = useStyles()

  const [formState, setFormState] = React.useState<ILogin>({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  })

  React.useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }))
  }, [formState.values])

  const handleChange = (event: any) => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()

    if (formState.isValid) {
      window.location.replace('/')
    }

    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors
      }
    }))
  }

  const hasError = (field: string) => (formState.touched[field] && formState.errors[field] ? true : false)

  return (
    <div>
      <Section className={classes.section}>
        <div className={classes.formContainer}>
          <SectionHeader
            title="Login"
            subtitle={
              <span>
                Don’t have an account?&nbsp;
                <CustomLink title="Sign up." href="/signup" typographyProps={{ variant: 'h6' }} />
              </span>
            }
            titleProps={{
              variant: 'h3'
            }}
          />
          <div className={classes.fullWidth}>
            <form name="password-reset-form" method="post" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    placeholder="E-mail"
                    label="E-mail *"
                    variant="outlined"
                    size="medium"
                    name="email"
                    fullWidth
                    helperText={hasError('email') ? formState.errors.email[0] : null}
                    error={hasError('email')}
                    onChange={handleChange}
                    type="email"
                    value={formState.values.email || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    placeholder="Password"
                    label="Password *"
                    variant="outlined"
                    size="medium"
                    name="password"
                    fullWidth
                    helperText={hasError('password') ? formState.errors.password[0] : null}
                    error={hasError('password')}
                    onChange={handleChange}
                    type="password"
                    value={formState.values.password || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <i>
                    <Typography variant="subtitle2">Fields that are marked with * sign are required.</Typography>
                  </i>
                </Grid>
                <Grid item xs={12}>
                  <Button size="large" variant="contained" type="submit" color="primary" fullWidth>
                    Login
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" color="textSecondary" align="center">
                    Forgot your password? <CustomLink title="Reset password" href="/password-reset" />
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default Login
