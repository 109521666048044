import clsx from 'clsx'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useMediaQuery, Button, Grid, Typography } from '@material-ui/core'
import { SectionHeader, HeroSideImage } from 'components/display'

import iDiagnostic from 'assets/courses/dianogstic-test-n-report.jpg'
import iInterview from 'assets/courses/interview-coaching-n-guidance.jpg'
import iInternationalBridging from 'assets/courses/international-bridging-course.jpg'
import iCCA from 'assets/courses/cca-n-enrichment-course.jpg'
import i1V1 from 'assets/courses/1-v-1.jpg'

const DATA = [
  {
    title: 'Acalyt Diagnostic Test & Report',
    content:
      "Acalyt's exclusive diagnostic tool to assess your child's academic and aptitude placement in an IB, O/A'Level / AP curriculum system.",
    img: iDiagnostic
  },
  {
    title: 'Interview coaching and guidance',
    content:
      'A team of experienced interview coach who are either an alumni or a previous teaching staff in your target school to provide exclusive One on One interview coaching and personal statement guidance.',
    img: iInterview
  },
  {
    title: 'International Bridging Course',
    content:
      '<p>To get you prepared and up to speed, concept by concept, of your target curriculum, be it IB / IGCSE or AP.</p><p>Available in all subjects.</p>',
    img: iInternationalBridging
  },
  {
    title: 'CCA and Enrichment Courses',
    content:
      '<p>We understand that academic rigor is not everything.</p><p>To reach where you want to reach, you need to demonstrate your full potential, in all aspects of life.</p><p>We help you craft your personal profile from Day One so that you are brilliant in and out. </p>',
    img: iCCA
  },
  {
    title: '1 v.s. 1 Lifelong Learning Plan',
    content:
      '<p>Acalyt provides life-long academic and career path consultancy services for students as young as pre-schoolers so that they understand and achieve their goals at every step of their teenage-hood and early adulthood. Suitable for families who are highly mobile all over the world.</p>',
    img: i1V1
  }
]

const useStyles = makeStyles((theme: any) => ({
  space: {
    padding: theme.spacing(0, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 10)
    }
  },
  fontWeight700: {
    fontWeight: 700
  },
  bgWhite: {
    backgroundColor: 'white',
    boxShadow: `0 2px 10px 0 ${theme.palette.cardShadow}`
  },
  bgGreen: {
    backgroundColor: '#1DB578'
  },
  bgDark: {
    backgroundColor: 'rgb(96 ,94 ,94)'
  },
  textWhite: {
    color: 'white'
  },
  textGreen: {
    color: '#1DB578'
  },
  textDark: {
    color: '#1D1D36'
  },
  desc: {
    fontSize: '18px'
  },
  learnMoreBtn: {
    marginTop: '15px'
  }
}))

const Features = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  })

  return (
    <>
      <SectionHeader title="5 Major Courses" fadeUp />

      <div data-aos="fade-up">
        {DATA.map((item, index) => {
          const isAlternate = index % 2 === 0

          return (
            <HeroSideImage
              className={isAlternate ? classes.bgWhite : classes.bgDark}
              imageSrc={item.img}
              reverse={isAlternate}
              key={index}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant={isMd ? 'h5' : 'h6'}
                    color="primary"
                    className={clsx(isAlternate ? classes.textDark : classes.textGreen, classes.fontWeight700)}>
                    {item.title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant={isMd ? 'body1' : 'body2'}
                    color="textSecondary"
                    className={clsx(classes.desc, isAlternate ? classes.textDark : classes.textWhite)}>
                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                  </Typography>
                  <Button variant="contained" color="primary" className={classes.learnMoreBtn}>
                    Learn More
                  </Button>
                </Grid>
              </Grid>
            </HeroSideImage>
          )
        })}
      </div>
    </>
  )
}

export default Features
