import { Hero, Section, SectionAlternate } from 'components/display'
import { Features, Courses } from './components'

const Home = () => {
  return (
    <div>
      <Hero />
      <SectionAlternate>
        <Features />
      </SectionAlternate>
      <Section>
        <Courses />
      </Section>
    </div>
  )
}

export default Home
