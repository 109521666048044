import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Drawer } from '@material-ui/core'
import SidebarNav from './components/nav'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '100%',
    maxWidth: 325
  },
  root: {
    height: '100%',
    padding: theme.spacing(1)
  },
  nav: {
    marginBottom: theme.spacing(1)
  }
}))

const Sidebar = (props: any) => {
  const { pages, open, variant, onClose, className, ...rest } = props

  const classes = useStyles()

  return (
    <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={() => onClose()} open={open} variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <SidebarNav className={classes.nav} pages={pages} onClose={onClose} />
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  pages: PropTypes.object.isRequired
}

export default Sidebar
