import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMediaQuery, Button, Grid, Typography } from '@material-ui/core'
import { Image } from 'components/html'
import { SectionHeader, CardBase } from 'components/display'
import tRoman from 'assets/teachers/roman.jpg'
import tVivienne from 'assets/teachers/vivienne.jpg'
import tEmily from 'assets/teachers/emily.jpg'
import tBerat from 'assets/teachers/prof-berat.jpg'
import tHeath from 'assets/teachers/heath.jpg'
import tSangha from 'assets/teachers/sangha.jpg'
import tEsther from 'assets/teachers/esther.jpg'
import tAdam from 'assets/teachers/adam.jpg'
import tJacob from 'assets/teachers/prof-jacob.jpg'

const DATA = [
  {
    name: 'Roman',
    img: tRoman,
    qualification: 'CELTA/TEFL/TESOL',
    subject: 'English, and Social Science',
    bg: 'bgSkyBlue',
    color: 'textNormal'
  },
  {
    name: 'Vivienne',
    img: tVivienne,
    qualification: 'Taught @ SG Public School',
    subject: 'English, Math and Science (Junior)',
    bg: 'bgMaroon',
    color: 'textWhite'
  },
  {
    name: 'Emily',
    img: tEmily,
    qualification: 'Taught @ IA (US top IB)',
    subject: 'English, Math and Science (Senior)',
    bg: 'bgDarkBlue',
    color: 'textWhite'
  },
  {
    name: 'Prof. Berat',
    img: tBerat,
    qualification: 'Taught @ Yale, NUS, NYU',
    subject: '23 Years+ EXP, IB/AP Expert',
    bg: 'bgMaroon',
    color: 'textWhite'
  },
  {
    name: 'Heath',
    img: tHeath,
    qualification: 'TEFL/TESOL English',
    subject: 'English, IB IGCSE Expert',
    bg: 'bgSkyBlue',
    color: 'textNormal'
  },
  {
    name: 'Sangha',
    img: tSangha,
    qualification: 'University of Birmingham',
    subject: 'English, Maths and Economics',
    bg: 'bgDarkBlue',
    color: 'textWhite'
  },
  {
    name: 'Esther',
    img: tEsther,
    qualification: 'Taught@Raffles Girl School, Nanyang Girl School',
    subject: "30 Years+ EXP, IB/A'level Expert",
    bg: 'bgMaroon',
    color: 'textWhite'
  },
  {
    name: 'Adam',
    img: tAdam,
    qualification: 'Taught @ Catholic (US)',
    subject: 'English Poetry, IB AP Expert',
    bg: 'bgDarkBlue',
    color: 'textWhite'
  },
  {
    name: 'Prof. Jacobs',
    img: tJacob,
    qualification: 'Taught @ NIE, UHM, UIUC',
    subject: '30 Years+ EXP, AP EAL Expert',
    bg: 'bgSkyBlue',
    color: 'textNormal'
  }
]

const useStyles = makeStyles((theme: any) => ({
  container: {
    position: 'relative'
  },
  introContainer: {
    position: 'absolute',
    bottom: '5%',
    left: '5%',
    background: theme.palette.background.primary,
    width: '70%',
    padding: '20px 15px'
  },
  name: {
    fontSize: '24px'
  },
  image: {
    objectFit: 'cover'
  },
  normalWeight: {
    fontWeight: 400
  },
  semiboldWeight: {
    fontWeight: 600
  },
  bookCourseBtn: {
    marginTop: '10px',
    backgroundColor: 'black',
    color: 'white'
  },
  bgSkyBlue: {
    backgroundColor: '#c9ecf5'
  },
  bgMaroon: {
    backgroundColor: '#a6180d'
  },
  bgDarkBlue: {
    backgroundColor: '#04244f'
  },
  textWhite: {
    color: 'white'
  },
  textNormal: {
    color: '#2d3748'
  }
}))

const Teachers = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  })

  return (
    <>
      <SectionHeader title="Our Teachers" fadeUp />
      <Grid container spacing={isMd ? 0 : 2}>
        {DATA.map((item, index) => (
          <Grid key={index} item alignItems="center" direction="column" xs={12} sm={6} md={4} data-aos="fade-up">
            <CardBase noPadding={true} bg="transparent">
              <div className={clsx(classes.container)}>
                <Image
                  src={item.img}
                  alt={item.name}
                  lazyProps={{ width: '100%', height: '100%' }}
                  className={classes.image}
                />

                <div
                  className={clsx(
                    classes.introContainer,
                    classes[item.bg as 'bgSkyBlue' | 'bgDarkBlue' | 'bgMaroon'],
                    classes[item.color as 'textWhite' | 'textNormal']
                  )}>
                  <Typography className={clsx(classes.name, classes.semiboldWeight)}>{item.name}</Typography>
                  <Typography className={classes.normalWeight} variant="body2">
                    {item.qualification}
                  </Typography>
                  <Typography className={classes.semiboldWeight} variant="body2">
                    {item.subject}
                  </Typography>

                  <Button variant="contained" className={classes.bookCourseBtn} size="small">
                    Book my Course
                  </Button>
                </div>
              </div>
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default Teachers
