import { useTheme } from '@material-ui/core/styles'
import { useMediaQuery, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Image } from 'components/html'
import { SectionHeader, CardBase } from 'components/display'

import iNTUIncubated from 'assets/feature/ntu-incubated.jpg'
import iFounding from 'assets/feature/founding-team.jpg'
import iCelebrityTutor from 'assets/feature/celebrity-tutor.jpg'
import iRiddikulus from 'assets/feature/riddikulus.jpg'
import iTrackRecords from 'assets/feature/track-records.jpg'
import iAStudents from 'assets/feature/a-students.jpg'

const DATA = [
  {
    title: 'NTU Incubated & MOE Funded',
    content:
      '<p>Aug 2016 - Incubated by Nanyang Technological University (2021QS World University Ranking No. 13)</p><br/><p>Apr 2017 - Obtained 1st round of funding from Singapore Ministry of Education via NTUitive Programme</p>',
    img: iNTUIncubated
  },
  {
    title: 'Founding Team Background',
    content:
      "<p>Founding members all graduated from Top 20 Universities (QS '21) in the World</p><br/><p>Stanford University<br/>National University of Singapore<br/>Nanyang Technological Universit",
    img: iFounding
  },
  {
    title: '260+ World-Class Celebrity Tutors',
    content:
      '<p>Our 260+ tutors have abundant teaching experience at various prestigious academic institutes all over the world.</p><p>To name a few:</p><ul style="padding-left: 20px"><li>Yale University</li><li>National University of Singapore</li><li>Nanyang Technological University</li><li>New York University</li><li>International Academy (US)</li><li>Appleby College (Canada)</li><li>Bishop\'s College School (Canada)</li><li>Raffles Institution (SG)</li></ul>',
    img: iCelebrityTutor
  },
  {
    title: 'RIDDIKULUS - Boggart Methodology',
    content:
      '<p><i>The spell that defeats a Boggart can be tricky, because it involves making the creature into a figure of fun, so that fear can be dispelled in amusement.</i><br/>---------- Harry Potter</p><p>RIDDIKULUS - Boggart is an original Acalyt learning methodology that aims to unravel the mystery of every unknown field of knowledge and make every student a master of knowledge, in laughters. </p>',
    img: iRiddikulus
  },
  {
    title: '5-Year Track Record in Singapore',
    content:
      '<p>Meeting with Indranee Rajah, Singapore Minister of Education & Koh Poh Koon, member of the parliament Koh Poh Koon to showcase our 5-year track record</p>',
    img: iTrackRecords
  },
  {
    title: "1000+ IBDP 40+/A'Level 4As Students",
    content:
      '<ul style="padding-left: 20px"><li>98.7% overall admission targets hit</li><li>Over 1000 alumni who scored 40+/45 in IBDP OR more than 4As in A\'Level</li></ul>',
    img: iAStudents
  }
]

const useStyles = makeStyles((theme: any) => ({
  container: {
    position: 'relative'
  },
  titleBg: {
    position: 'absolute',
    bottom: 0,
    background: theme.palette.background.primary,
    width: '100%',
    padding: '5px 15px'
  },
  titleText: {
    color: 'white',
    fontSize: '22px',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  image: {
    objectFit: 'cover'
  },
  hoverContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.primary,
    color: 'white',
    opacity: 0,
    transition: '.3s',
    boxSizing: 'border-box',
    cursor: 'default',
    padding: '1rem',
    textAlign: 'left',
    fontSize: '18px',
    '&:hover': {
      opacity: 1
    }
  }
}))

const Features = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  })

  return (
    <>
      <SectionHeader title="Pure Blood International K12" fadeUp />
      <Grid container spacing={isMd ? 4 : 2}>
        {DATA.map((item, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            md={4}
            data-aos="fade-up">
            <CardBase noPadding={true} bg="primary">
              <div className={classes.container}>
                <Image
                  src={item.img}
                  alt={item.title}
                  lazyProps={{ width: '100%', height: '100%' }}
                  className={classes.image}
                />
                <div className={classes.hoverContent}>
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
                <div className={classes.titleBg}>
                  <Typography className={classes.titleText}>{item.title}</Typography>
                </div>
              </div>
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default Features
