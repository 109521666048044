import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import HeroBackground from './bg'
import HeroBg from 'assets/home-hero.jpg'

const useStyles = makeStyles((theme: any) => ({
  space: {
    padding: theme.spacing(6, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 8)
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 8)
    }
  },
  title: {
    fontFamily: 'Chelsea Market',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#20CE88'
  }
}))

const Hero = (props: any) => {
  const { className, ...rest } = props
  const classes = useStyles()

  return (
    <div className={className} {...rest}>
      <HeroBackground className={classes.space} backgroundImg={HeroBg}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.title}>
              Global Education without leaving the Comfort of Home
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" className={clsx(classes.title)} gutterBottom>
              Blue Blood K12 International Education
            </Typography>
          </Grid>
        </Grid>
      </HeroBackground>
    </div>
  )
}

Hero.propTypes = {
  className: PropTypes.string
}

export default Hero
