import { Section } from 'components/display'
import { Teachers } from './components'

const About = () => {
  return (
    <div>
      <Section>
        <Teachers />
      </Section>
    </div>
  )
}

export default About
